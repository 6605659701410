<template>
  <v-container fluid tag="section">
    <v-card>
      <v-row justify="center">
        <v-col cols="12" md="11">
          <base-material-card color="blue">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light" color="white">
                {{ $phraser.set('create user') }}
              </div>
            </template>
          </base-material-card>
          <v-form ref="form" :disabled="loading" @submit.prevent="submit">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.first_name"
                    :label="$phraser.set(`first name`)"
                    :placeholder="$phraser.set(`first name`)"
                    min="2"
                    max="50"
                    required
                    :regex="new RegExp('^[a-zA-Z\\s\\u0590-\\u05FF]+$')"
                    :regex-message="'form.name'"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.last_name"
                    :label="$phraser.set(`last name`)"
                    :placeholder="$phraser.set(`last name`)"
                    min="2"
                    max="50"
                    required
                    :regex="new RegExp('^[a-zA-Z\\s\\u0590-\\u05FF]+$')"
                    :regex-message="'form.name'"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    type="email"
                    :model.sync="form.email"
                    :label="$phraser.set(`email`)"
                    :placeholder="$phraser.set(`email`)"
                    dir="ltr"
                    required
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <!-- <v-text-field
                    label="אימות אימייל"
                    placeholder="אימות אימייל"
                    dir="ltr"
                    :rules="confirmEmailRules"
                    required
                  /> -->
                  <base-smart-input
                    type="email"
                    :model.sync="form.email_confirmation"
                    label="אימות אימייל"
                    placeholder="אימות אימייל"
                    dir="ltr"
                    required
                    :compare="form.email"
                    compare-name="אימייל"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    type="password"
                    :model.sync="form.password"
                    :label="$phraser.set(`password`)"
                    :placeholder="$phraser.set(`password`)"
                    min="6"
                    max="18"
                    counter
                    dir="ltr"
                    required
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    type="password"
                    :model.sync="form.password_confirmation"
                    label="אימות סיסמה"
                    placeholder="אימות סיסמה"
                    dir="ltr"
                    required
                    :compare="form.password"
                    compare-name="סיסמה"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <!-- :label="$phraser.set(`phone`) + ' - אינך רשאי להוסיף'" -->
                  <base-smart-input
                    :model.sync="form.phone"
                    :label="$phraser.set(`phone`)"
                    :placeholder="$phraser.set(`phone`)"
                    min="9"
                    max="10"
                    numberic
                    required
                    type="tel"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="form.client"
                    :items="Object.values(clients)"
                    item-value="code"
                    label="לקוח"
                    placeholder="לקוח"
                    required
                    :rules="clientRules"
                    class="bidi"
                  >
                    <template v-slot:selection="data">
                      <v-row class="ms-3">
                        <h3 class="blue--text text--darken-3">
                          {{ data.item.name }}
                        </h3>
                        <small class="text-left ms-3">
                          ({{ data.item.code }})
                        </small>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item v-bind="data.attrs" v-on="data.on">
                        <v-list-item-content>
                          <v-list-item-title
                            class="d-flex justify-content-between align-center"
                          >
                            <small class="text-left me-3">
                              ({{ data.item.code }})
                            </small>
                            <h3 class="blue--text text--darken-3">
                              {{ data.item.name }}
                            </h3>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="success"
                    class="mr-0 text-h4"
                    block
                    type="submit"
                  >
                    {{ $phraser.set('create user') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CreateUser',

  data: () => ({
    form: {},
    initiated: false,
    loading: false,
    clientRules: [v => !!v || 'שדה חובה'],
    showP: false,
    // confirmEmailRules: ,
  }),

  computed: {
    clients () {
      return this.$store.getters['clients/all']
    },
    supplierRules () {
      return [v => !!v || this.$t('form.required')]
    },
    confirmEmailRules () {
      return [
        v => !!v || 'שדה חובה',
        v => v === this.form.email || 'האימייל אינו זהה',
      ]
    },
    confirmPasswordRules () {
      return [
        v => !!v || 'שדה חובה',
        v => v === this.form.password || 'הסיסמה אינה זהה',
      ]
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },
  mounted () {
    // :dir="$refs.pwds.value ? 'ltr' : 'rtl'"
    // console.log(this.clients)
  },

  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        // console.log(this.form)
        this.loading = true
        await this.$store.dispatch('users/createUser', this.form)
        this.loading = false
        // const slug = this.form.email.replaceAll('.', '~')
        const slug = this.form.email.split('.').join('~')
        this.$router.push('/users/' + slug)
      }
    },

    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
<style scoped>
.width100 {
  min-width: 100px;
}
.width250 {
  min-width: 250px;
}
</style>
